import "./FixedWindow.scss"
import { msgSvg, iconPhone } from "../../service/image"

const FixedWindow = () => {

	return (
		<div className="fixed-window">
			<div className="fixed-window__wrapper row">
				<a href="tel:+380734087770">
					<img src={iconPhone} alt="icon" />
					<span>Подзвонити</span>
				</a>
				<a href="https://t.me/shining_house_clining">
					<img src={msgSvg} alt="icon" />
					<span>Написати</span>
				</a>
			</div>
		</div>
	)
}

export default FixedWindow