import "./Header.scss"

import { iconPhone, iconLogo } from "../../service/image"
import Button from "../Button/Button"

const Header = () => {
	return (
		<header>
			<div className="container">
				<div className="row">
					<a href="/" className="header__logo logo row">
						<img src={iconLogo} alt="logo" />
						<p>Сяючий Будинок </p>
					</a>
					<nav className="row">
						<a href="tel:+380734087770" className="row header__phone"><img src={iconPhone} alt="phone" /> <p>+38 (073) 40-87-770</p></a>
						<Button type={"consultation"} />
					</nav>
				</div>
			</div>
		</header>
	)
}

export default Header