import "./Prices.scss"
import Button from "../Button/Button"
import { p1, p2, p3, p4, p5, p6, p7, imageAbout2 } from "../../service/image"

const Prices = () => {

	return (
		<section className="prices">
			<div className="container">
				<h2>У нас зрозумілі і прозорі ціни на клінінг</h2>
				<div className="row">
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p1} alt="photo" />
							<h3>Підтримуюче прибирання</h3>
							<span>Приберемо Ваше житло швидко і надійно</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 1500 грн</p>
							<Button type={"posluga_1"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p2} alt="photo" />
							<h3>Генеральне прибирання</h3>
							<span>Проводимо ефективне Генеральне прибирання</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 3000 грн</p>
							<Button type={"posluga_2"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p3} alt="photo" />
							<h3>Після ремонту</h3>
							<span>без меблів від 60 грн/1квм <br />з меблями від 100 грн/1квм</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 4000 грн</p>
							<Button type={"posluga_3"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p4} alt="photo" />
							<h3>Миття вікон</h3>
							<span>Сезонне - 200 грн/1квм <br />Після Ремонту - 300 грн/1квм</span>
						</div>
						<div className="prices__card-bottom">
							<p>Від 200 грн</p>
							<Button type={"posluga_4"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p5} alt="photo" />
							<h3>Хімчистка мʼяких меблів</h3>
							<span>Тільки якісна та безпечна еко-хімія</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 1200 грн</p>
							<Button type={"posluga_5"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p6} alt="photo" />
							<h3>Дезінфекція</h3>
							<span>Швидка Дезінфекція вашого житла вже зараз</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 1500 грн</p>
							<Button type={"posluga_6"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={imageAbout2} alt="photo" />
							<h3>Окремі послуги</h3>
							<span>Генеральне прибирання Санвузла - 2500 грн <br />Генеральне прибирання кухні від 3000 грн</span>
						</div>
						<div className="prices__card-bottom">
							<p>від 2500 грн</p>
							<Button type={"posluga_7"} />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<img src={p7} alt="photo" />
							<h3>Додаткові послуги</h3>
							<span>Не знайшов потрібну послугу? Телефонуй і ми глянемо що можемо зробити</span>
						</div>
						<div className="prices__card-bottom">
							<p>по домовленості</p>
							<Button type={"posluga_8"} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Prices