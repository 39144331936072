const _CHAT_ID = "-1002095857627"
const _TG_TOKEN_BOT = "7165795685:AAGDjScQ_dC_M-J2AGhcfBejLb4wurBCjO8"
const _TG_URL = `https://api.telegram.org/bot${_TG_TOKEN_BOT}/sendMessage`

async function sendData(message) {
	try {
		return await fetch(_TG_URL, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				chat_id: _CHAT_ID,
				text: message,
				parse_mode: "html"
			}),
		})
	} catch (error) {
		return error
	}
}

export default sendData;


