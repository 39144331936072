import "./Feedback.scss"

import { iconYuli, iconTaras, iconNataly } from "../../service/image"

const Feedback = () => {
	return (
		<section className="feedback">
			<div className="container">
				<h2>Відгуки наших клієнтів</h2>
				<div className="row feedback__wrapper">
					<div className="feedback__item">
						<div className="feedback__item-user row">
							<img src={iconYuli} alt="iconYuli" />
							<h3>Юлія Майстренко</h3>
						</div>
						<p className="feedback__item-text">Це дійсно крута команда!! У них є все потрібне для того, щоб Ваш будинок був ідеально чистим!!! Працюють зладжено та швидко! Якість понад усе! Рекомендую!!! Дуже задоволена результатом! Ціни доступні!</p>
					</div>
					<div className="feedback__item">
						<div className="feedback__item-user row">
							<img src={iconTaras} alt="iconTaras" />
							<h3>Taras Tarasovych</h3>
						</div>
						<p className="feedback__item-text">Звітність, точність і прекрасний результат - ось, що відрізняє "Сяючий Будинок". їхній професіоналізм і уважність до деталей забезпечують нашому будинку бездоганну чистоту кожного разу. Рекомендую їх всім, хто цінує якість і надійність в послугах прибирання!</p>
					</div>
					<div className="feedback__item">
						<div className="feedback__item-user row">
							<img src={iconNataly} alt="iconNataly" />
							<h3>Наталія Ярмоленко</h3>
						</div>
						<p className="feedback__item-text">Прекрасний клінінг. Роботу виконують якісно. Замовляли чистку дивана. Рекомендую.</p>
					</div>
				</div>

				<a target="blank" href="https://www.google.com/search?hl=uk-UA&gl=ua&q=%D0%9A%D0%BB%D1%96%D0%BD%D1%96%D0%BD%D0%B3+%D0%A1%D1%8F%D1%8E%D1%87%D0%B8%D0%B9+%D0%91%D1%83%D0%B4%D0%B8%D0%BD%D0%BE%D0%BA,+%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%9B%D0%B8%D0%BF%D0%B8%D0%BD%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE,+6,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+79000&ludocid=10887024235524864662&lsig=AB86z5UFT__PSi_yQEs-n_6HJQT7&hl=uk&gl=UA#lrd=0x473add004e71d8b5:0x97167adc3b873a96,1">Переглянути більше</a>
			</div>
		</section>
	)
}

export default Feedback