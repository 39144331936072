import "./Advice.scss"
import { iconClock, iconMarker, iconPhone } from "../../service/image"

import Form from "../Form/Form"

const Advice = () => {
	return (
		<section className="advice">
			<div className="container">
				<h2>Замов безкоштовну консультацію</h2>
				<div className="advice__wrapper row">
					<div className="advice__left">
						<Form />
					</div>

					<div className="advice__right row">
						<a className="row" href="tel:+380734087770"> <img src={iconPhone} alt="iconPhone" /> <p>+38 (073) 40-87-770</p></a>
						<a className="row" href="https://www.google.com/maps/place/%D0%9A%D0%BB%D1%96%D0%BD%D1%96%D0%BD%D0%B3+%D0%A1%D1%8F%D1%8E%D1%87%D0%B8%D0%B9+%D0%91%D1%83%D0%B4%D0%B8%D0%BD%D0%BE%D0%BA/@49.8569131,24.00915,15z/data=!4m6!3m5!1s0x473add004e71d8b5:0x97167adc3b873a96!8m2!3d49.8560611!4d24.0205306!16s%2Fg%2F11vt9ntb4y?entry=ttu"> <img src={iconMarker} alt="iconMarker" /> <p>вулиця Липинського 6, місто Львів</p></a>
						<p className="row"> <img src={iconClock} alt="iconClock" /> <span>Працюємо з ПН по СБ від 9:00 до 22:00</span></p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Advice

