import "./Map.scss"

const Map = () => {
	return (
		<section className="map">
			<div className="container">
				<h2>Де ми знаходимось?</h2>
				<iframe title="Карта клінінгу" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10289.050727665684!2d24.011690039086876!3d49.85631011093914!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add004e71d8b5%3A0x97167adc3b873a96!2z0JrQu9GW0L3RltC90LMg0KHRj9GO0YfQuNC5INCR0YPQtNC40L3QvtC6!5e0!3m2!1suk!2sua!4v1715170902682!5m2!1suk!2sua" width="100%" height="420" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</section>
	)
}

export default Map