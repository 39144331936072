import "./Modal.scss"
import Form from "../Form/Form"
import { useModal } from "../../service/ModalContext"

import { iconCross } from "../../service/image"

const Modal = () => {
	const { activeModal, setActiveModal } = useModal();

	return (
		<div className={`modal ${activeModal && "active"}`}>
			<div className="modal__window">
				<h2>Безкоштовна консультація</h2>
				<Form />
				<button className="modal__close" onClick={() => setActiveModal(false)}>
					<img src={iconCross} alt="x" />
				</button>
			</div>
		</div>
	)
}

export default Modal