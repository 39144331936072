import "./Footer.scss"

import { iconLogo } from "../../service/image"
import { iconFacebook, iconInstagram, iconTelegram, iconTikTok } from "../../service/image"

const Footer = () => {

	return (
		<footer>
			<div className="container">
				<div className="row">
					<a href="/" className="footer__logo logo row">
						<img src={iconLogo} alt="logo" />
						<p>Сяючий Будинок </p>
					</a>

					<div className="footer__social row">
						<a target="blank" href="https://www.tiktok.com/@shining_houselviv?_t=8m926Fz07Us&_r=1"><img src={iconTikTok} alt="iconTikTok" /></a>
						<a target="blank" href="https://www.facebook.com/profile.php?id=61556531195974"><img src={iconFacebook} alt="iconFacebook" /></a>
						<a target="blank" href="https://www.instagram.com/shining_house2024?igsh=eGdxNzd1aGZ1bWcy"><img src={iconInstagram} alt="iconInstagram" /></a>
						<a target="blank" href="https://t.me/shining_house_clining"><img src={iconTelegram} alt="iconTelegram" /></a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer