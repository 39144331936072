import "./Form.scss"
import InputMask from 'react-input-mask';
import { useModal } from "../../service/ModalContext";

import sendData from "../../service/sendForm"
import { useState } from "react"

const allQuery = {
	consultation: "Консультація",
	posluga_1: "Підтримуюче прибирання",
	posluga_2: "Генеральне прибирання",
	posluga_3: "Після ремонту",
	posluga_4: "Миття вікон",
	posluga_5: "Хімчистка мʼяких меблів",
	posluga_6: "Дезінфекція",
	posluga_7: "Окремі послуги",
	posluga_8: "Додаткові послуги"
}

const Form = ({ textBtn }) => {
	const [inpPhone, setInpPhone] = useState("")
	const [messageForm, setMessageForm] = useState(null)
	const [messageColor, setMessageColor] = useState("")
	const { formType, setFormType } = useModal()

	const sendMessage = (event) => {
		event.preventDefault()
		console.log(inpPhone.length)
		const phoneRegex = /^\+38\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;

		if (phoneRegex.test(inpPhone)) {
			setMessageForm(null)
			const currentDate = new Date();
			const options = {
				timeZone: 'Europe/Kiev',
				hour12: false,
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit'
			};
			const formattedDate = currentDate.toLocaleString('uk-UA', options);
			let typeQuery

			if (formType) {
				typeQuery = allQuery[formType]
			} else {
				typeQuery = "Консультація"
			}

			const messageToSend = `<b>Заявка з сайту</b>\n\nДата: ${formattedDate}\n\nНомер телефону:\n${inpPhone}\n\nТип звернення:\n${typeQuery}`

			sendData(messageToSend).then(data => {
				if (data.ok) {
					setMessageColor(true)
					setMessageForm("Заявку успішно відправлено!")
					setFormType("consultation")
					window.location.href = '/thank-you.html?submitted=true';

					setTimeout(() => {
						setMessageForm(null)
					}, 3000)
				} else {
					setMessageColor(false)
					setMessageForm("Щось пішло не так... Спробуйте пізніше!")
					setTimeout(() => {
						setMessageForm(null)
					}, 3000)
				}
			})
		} else {
			setMessageColor(false)
			setMessageForm("Ви маєте ввести номер телефону!")
			setTimeout(() => {
				setMessageForm(null)
			}, 3000)
		}
	}

	return (
		<div className="form" onSubmit={(event) => sendMessage(event)}>
			<p className="form-text">Виникло питання? <br /> Або хочете замовити прибирання?</p>
			<p className="form-text">Залиште номер і ми передзвонимо</p>
			<form>
				{/* <input onChange={(event) => { setInpPhone(event.target.value) }} value={inpPhone} type="text" placeholder="Введіть номер телефону" /> */}
				<InputMask
					onChange={(event) => setInpPhone(event.target.value)}
					value={inpPhone}
					mask="+38 (099) 999-99-99"
					placeholder="Введіть номер телефону"
				/>
				{messageForm && <p className={`form__message ${messageColor ? "green" : "red"}`}>{messageForm}</p>}
				<button className="btn">{textBtn ? textBtn : "Чекаю дзвінок"}</button>
			</form>
		</div>
	)
}

export default Form

