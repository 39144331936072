import { createContext, useState, useContext, useEffect } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [activeModal, setActiveModal] = useState(false);
	const [formType, setFormType] = useState("consultation")

	useEffect(() => {
		const openModalTimeout = setTimeout(() => {
			setActiveModal(true);
		}, 15000);

		const resetTimer = () => {
			clearTimeout(openModalTimeout);
		};

		const handleButtonClick = (event) => {
			if (event.target.tagName === "BUTTON") {
				resetTimer();
			}
		};

		window.addEventListener("click", handleButtonClick);

		return () => {
			clearTimeout(openModalTimeout);
			window.removeEventListener("click", handleButtonClick);
		};
	}, []);

	return (
		<ModalContext.Provider value={{ activeModal, setActiveModal, setFormType, formType }}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);
