import Header from "../Header/Header"
import Hero from "../Hero/Hero"
import About from "../About/About"
import Advantages from "../Advantages/Advantages"
import Prices from "../Prices/Prices"
import Map from "../Map/Map"
import Feedback from "../Feedback/Feedback"
import Advice from "../Advice/Advice"
import Footer from "../Footer/Footer"
import Modal from "../Modal/Modal"
import FixedWindow from "../FixedWindow/FixedWindow"
import { useState } from "react"

import { ModalProvider } from "../../service/ModalContext"

const App = () => {

	return (
		<ModalProvider>
			<Header />
			<main>
				<Hero />
				<About />
				<Prices />
				<Advantages />
				<Map />
				<Feedback />
				<Advice />
				<Modal />
			</main>
			<FixedWindow />
			<Footer />
		</ModalProvider>
	)
}

export default App