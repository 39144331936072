import { useModal } from "../../service/ModalContext"

const Button = ({ type }) => {
	const { activeModal, setActiveModal, setFormType } = useModal();

	const buttonHandleClick = () => {
		setActiveModal(true)
		console.log(setFormType, type)
		if (type) {
			setFormType(type)
		}
	}

	return (
		<button className="btn" onClick={buttonHandleClick}>Замовити прибирання</button>
	)
}

export default Button

