import "./Hero.scss"
import { imageHero } from "../../service/image"
import Button from "../Button/Button"
import Form from "../Form/Form"

const Hero = () => {
	return (
		<section className="hero">
			<div className="container">
				<div className="row">
					<div className="hero__image">
						<img src={imageHero} alt="MainImage" />
					</div>
					<div className="hero__descr">
						<h1 className="logo">Сяючий Будинок </h1>
						<p className="hero__subtitle">Клінінгова компанія №1 у Львові</p>
						<p className="hero__citation">Блиск і чистота <br />
							кожного куточка вашого будинку</p>
						<p className="hero__citation" style={{ fontFamily: "monospace", fontSize: "24px" }}>🧹Працюємо при відключення світла. 💡Енергонезалежні</p>
						<p className="hero__citation" style={{ fontFamily: "monospace", fontSize: "24px" }}>‼️При замовленні Генерального прибирання <br /> ви отримуєте <span style={{ fontWeight: 900, color: "red" }}>ЗНИЖКУ -30%</span> на Хімчистку меблів</p>
						{/* <Button /> */}
						<Form textBtn={"Безкоштовна консультація"} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero